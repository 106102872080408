import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { Layout } from "../components/layout";
import { useNews } from "../hooks/useNews";
import { ScrollAndShow } from "../components/scroll-and-show";
import { Stories } from "../components/stories";
import { EventReports } from "../components/event-reports";
const startButtonImgSrc = require("../images/start_button.png");
const closeImgSrc = require("../images/close.png");
import { SEO } from "../components/seo";

import { app } from "../lib/firebaseClient";
import { getFirestore, doc, getDoc } from "firebase/firestore/lite";

export const db = getFirestore(app);

type About = {
	rubia: string;
	rubiaEn: string;
	tahona: string;
	tahonaEn: string;
	sarasa: string;
	sarasaEn: string;
	edo: string;
	edoEn: string;
};

const AboutPage = () => {
	const videoElement = useRef(null);

	const [t, i18n] = useTranslation();

	const { news } = useNews();

	const [showVideo, setShowVideo] = useState(false);

	const handleCloseVideo = () => {
		videoElement.current.pause();
		setShowVideo(false);
	};

	const [rubia, setRubia] = useState("");
	const [rubiaEn, setRubiaEn] = useState("");
	const [tahona, setTahona] = useState("");
	const [tahonaEn, setTahonaEn] = useState("");
	const [sarasa, setSarasa] = useState("");
	const [sarasaEn, setSarasaEn] = useState("");
	const [edo, setEdo] = useState("");
	const [edoEn, setEdoEn] = useState("");

	useEffect(() => {
		const fetchAbout = async () => {
			const docRef = await getDoc(doc(db, "rubia", "about"));
			const { rubia, rubiaEn, tahona, tahonaEn, sarasa, sarasaEn, edo, edoEn } =
				docRef.data() as About;

			setRubia(rubia);
			setRubiaEn(rubiaEn);
			setTahona(tahona);
			setTahonaEn(tahonaEn);
			setSarasa(sarasa);
			setSarasaEn(sarasaEn);
			setEdo(edo);
			setEdoEn(edoEn);
		};

		fetchAbout();
	}, []);

	return (
		<Layout>
			<SEO
				title="ABOUT | RUBIA ルビア 渋谷メキシカン【朝食・ランチ・バー】"
				description="エド・コバヤシ（Edo Kobayashi Group）とSARASA（CASA DE SARASA）が共同オーナーを務め、和食のような親しみやすさを感じながらメキシコ料理を楽しめるレストラン。豊富な種類のテキーラやメスカルを取り揃えており、日本とメキシコのコラボレーションをコンセプトにしたカクテルもお楽しみいただけます。"
				keywords={[
					"エドコバヤシ",
					"sarasa",
					"テキーラ",
					"メスカル",
					"casadesarasa",
				]}
			/>
			<h1 className="h1">ABOUT | RUBIA ルビア / DJ sarasa</h1>
			<div className="about">
				<div className={`video ${showVideo ? "show" : ""}`}>
					<button onClick={() => handleCloseVideo()}>
						<img src={closeImgSrc.default} alt="close" />
					</button>
					<video
						ref={videoElement}
						src="https://storage.googleapis.com/static.rubia.co.jp/about/movie.mov"
						controls
					/>
				</div>
				<div className="md:flex md:justify-end">
					<div>
						<ScrollAndShow>
							<div className="hero-image-wrapper">
								<img
									src="https://storage.googleapis.com/static.rubia.co.jp/about/about.jpg"
									alt="about"
								/>
								<div className="page-title font-lato">
									1F RUBIA
									<button
										onClick={() => setShowVideo(true)}
										className="pc start-button"
									>
										<img src={startButtonImgSrc.default} alt="start video" />
									</button>
								</div>
							</div>
							<button
								onClick={() => setShowVideo(true)}
								className="sp start-button"
							>
								<img src={startButtonImgSrc.default} alt="start video" />
							</button>
						</ScrollAndShow>

						<ScrollAndShow>
							<div className="about-text">
								{i18n.language === "ja" ? rubia : rubiaEn}
							</div>
						</ScrollAndShow>
					</div>
				</div>

				<div className="md:flex md:justify-end">
					<div>
						<ScrollAndShow>
							<div className="hero-image-wrapper">
								<img
									src="https://storage.googleapis.com/static.rubia.co.jp/about/about_tahona.jpg"
									alt="about_tahona"
								/>
								<div className="page-title font-lato">
									2F TAHONA
									<button
										onClick={() => setShowVideo(true)}
										className="pc start-button"
									>
										{/* <img src={startButtonImgSrc.default} alt="start video" /> */}
									</button>
								</div>
							</div>
							<button
								onClick={() => setShowVideo(true)}
								className="sp start-button"
							>
								<img src={startButtonImgSrc.default} alt="start video" />
							</button>
						</ScrollAndShow>

						<ScrollAndShow>
							<div className="about-text">
								{i18n.language === "ja" ? tahona : tahonaEn}
							</div>
						</ScrollAndShow>
					</div>
				</div>

				<div className={`person sarasa ${i18n.language}`}>
					<ScrollAndShow>
						<div className="image sp">
							<img
								src="https://storage.googleapis.com/static.rubia.co.jp/about/sarasa.png"
								alt="sarasa"
							/>
						</div>
					</ScrollAndShow>
					<ScrollAndShow>
						<div className="name text-white font-lato">SARASA</div>
						<div className="description text-white">
							{i18n.language === "ja" ? sarasa : sarasaEn}
							<Link to="/stories/ABOUT%20SARASA">MORE</Link>
						</div>
					</ScrollAndShow>
					<ScrollAndShow delay={1000}>
						<div className="image pc">
							<img
								src="https://storage.googleapis.com/static.rubia.co.jp/about/sarasa.png"
								alt="sarasa"
							/>
						</div>
					</ScrollAndShow>
				</div>

				<div className={`person edo ${i18n.language}`}>
					<ScrollAndShow>
						<div className="image">
							<img
								src="https://storage.googleapis.com/static.rubia.co.jp/about/edo.png"
								alt="edo"
							/>
						</div>
					</ScrollAndShow>

					<ScrollAndShow delay={1000}>
						<div className="name font-lato">Edo Kobayashi</div>
						<div className="description">
							{i18n.language === "ja" ? edo : edoEn}
							<Link to="/stories/ABOUT%20EDO%20KOBAYASHI">MORE</Link>
						</div>
					</ScrollAndShow>
				</div>

				<Stories />

				<EventReports />

				<div className="news-page">
					<ScrollAndShow>
						<div className="stories-text font-lato text-center mb-5">
							<Link to="/news">NEWS</Link>
						</div>
					</ScrollAndShow>
					<ScrollAndShow>
						<div className="inner">
							{news.map((newsItem, index) => (
								<Link to={`/news/${newsItem.id}`}>
									<div className="news-row" key={index}>
										<div className="font-lato inner-date">
											{format(new Date(newsItem.date), "yyyy.MM.dd")}
										</div>
										<div
											className={`${
												i18n.language === "en" && newsItem.titleEn
													? "font-lato"
													: "font-yu"
											} inner-title`}
										>
											{(i18n.language === "en" && newsItem.titleEn) ||
												newsItem.title}
										</div>
									</div>
								</Link>
							))}
						</div>
					</ScrollAndShow>

					<ScrollAndShow>
						<div className="more-button">
							<Link to="/news" className="font-lato">
								MORE
							</Link>
						</div>
					</ScrollAndShow>
				</div>
			</div>
		</Layout>
	);
};

export default AboutPage;
